<template>
  <div class="">
     <button
            class="btn px-3 py-2 ml-2 d-flex justify-content-center align-items-center shopify-btn"
            @click="openWixProductModal()">
            import from <i class="fab fa-wix ml-2" style="font-size:20px"></i>
          </button>
   

   

    <!-- Wix Modal -->
    <ModalVue
      ref="Modal"
      id="copyWixProducts"
      title="Import from Wix"
      size="lg">
      <div class="my-2" :class="!isLoadingSync ? 'd-none' : 'd-block'">
        <ProgressbarVue ref="progressbar"></ProgressbarVue>
        <span
          class="text-success text-center"
          style="font-size: 12px; text-align: center">
          Collections syncing soon, continue tasks uninterrupted.
        </span>
      </div>
      <FilterBox
              :search="true"
              placeholder="Search Collections..."
              @input="searchProductQueryHandle($event)"></FilterBox>
      
      <div class="loader px-2">
        <div
          class="d-flex py-2"
          :class="[
            isLoadingWixProducts
              ? 'justify-content-between'
              : 'justify-content-end',
          ]">
          <div
            class="d-flex align-items-center"
            v-if="isLoadingWixProducts">
            <b-spinner small style="color: #4d1b7e"></b-spinner>
            <span class="ml-2">Loading Collections</span>
          </div>
          <div class="float-right" v-if="wixSelectedProducts.length">
            Selected Collections : {{ wixSelectedProducts.length }}
          </div>
        </div>
      </div>
      <b-skeleton-table
        v-if="isLoadingWixProductFirstTime"
        :rows="5"
        :columns="3"
        :table-props="{ bordered: true, striped: true }"></b-skeleton-table>
      <div class="data-table" v-else-if="wixProducts.length">
        <div
          class=""
          style="max-height: 300px; min-height: 300px; overflow: auto">
          <b-table
            head-class="text-center"
            class="leadTable"
            :stickyColumn="true"
            hover
            :fields="wixProductsFields"
            :items="wixProducts">
            <template #head(checkbox)>
              <b-form-checkbox
                size="lg"
                :disabled="
                  isLoadingWixProducts ||
                  isLoadingSelectedWixProducts ||
                  isLoadingSync
                "
                v-model="shopifySelectAllCheck"
                @change="wixProductselectAllToggle($event)"
                class="checkbox selectAll"></b-form-checkbox>
            </template>
            <template #cell(checkbox)="data">
              <b-form-checkbox
                :value="data.item.id"
                v-model="wixSelectedProducts"
                :disabled="
                  isLoadingWixProducts ||
                  isLoadingSelectedWixProducts ||
                  isLoadingSync
                "
                size="lg"
                class="checkbox"></b-form-checkbox>
            </template>
             <template #cell(image)="data">
              <div class="d-flex gap align-items-center">
                <lazy-image
                     class="circle-image mr-2"
                     :src="
                        data.item.image
                         ? data.item.image
                         : 'https://images.quizell.com/website/default_start_page.png'
                     "
                     :key="data.item.image"></lazy-image>
                <div class="">{{ data.item.name }}</div>
              </div>
            
            </template>
          </b-table>
        </div>
      </div>
      <template v-else>

<div class="d-flex justify-content-center align-items-center" style="height: 50vh;" >

<EmptyTable >
<template #title>
No collection found
</template>
<template #description>
Time to fill your shelves or refine your search. Remember, your results will appear right here.
</template>
</EmptyTable>
</div>

</template>
      <div class="d-flex justify-content-between">
        <ShopifyPagination
          :hasNext="pageInfo.currentPage<pageInfo.totalPages"
          @onNext="
            () => {
              pageInfo.currentPage++
              getProductsFromWix();
            }
          "
          :hasPrevious="pageInfo.currentPage>1"
          @onPrevious="
            () => {
              pageInfo.currentPage--
              getProductsFromWix();
            }
          "></ShopifyPagination>
           <div
            class=" "
            >
            <Button
              class=""
              @click="syncSelected()"
              :disabled="
                isLoadingSync ||
                isLoadingWixProducts ||
                isLoadingSelectedWixProducts
              "
              :isLoading="isLoadingSelectedWixProducts"
              style="border-radius: 4px !important"
              v-if="wixSelectedProducts.length"
              >Sync Selected</Button
            >
            <button
              :disabled="
                isLoadingSync ||
                isLoadingWixProducts ||
                isLoadingSelectedWixProducts
              " v-else
              class="btn d-flex px-3 justify-content-center align-items-center py-2 shopify-btn"
              @click="syncAllCollections()">
              <div v-if="isLoadingSync" class="d-flex align-items-center">
                <b-spinner small></b-spinner>
                <span class="ml-1">Syncing...</span>
              </div>

              <span v-else>Sync all collections</span>
            </button>
          </div>
      </div>
    </ModalVue>
  
      
  </div>
 
</template>
<script>
import { mapGetters } from "vuex";
import { debounce } from "lodash";
import EmptyTable from "../../Layout/EmptyTable.vue";
import ShopifyPagination from "../../Layout/ShopifyPagination.vue";
import FilterBox from "../../Layout/FilterBox.vue";

import ModalVue from "../../Layout/Modal.vue";
import ProgressbarVue from "../../Layout/Progressbar.vue";
import axios from "axios";
import Button from '../../Layout/Button.vue';



export default {
  components: {
    FilterBox,
    EmptyTable,
    ShopifyPagination,
    ModalVue,
    Button,
    ProgressbarVue,
   
   
  },
  data() {
    return {
      isLoadAddProduct:false,
      selectAllQuizellProducts: false,
      selectedQuizellProducts: [],
      searchProductText: "",
      currentPage: 1,
      perPage: 10,
      totalRows: 10,
      pageInfo: {
        currentPage: 1,
        totalPages: 1,
        
      },
      isLoadingWixProducts: false,
      isLoadingWixProductFirstTime: false,
      shopifySelectAllCheck: false,
      wixSelectedProducts: [],
    
      wixProducts: [],
      wixProductsFields: [
        {
          key: "checkbox",
          label: "",
          tdClass: "align-middle data-Table cursor-pointer",
          thClass: "bg-table-heading",
        },
        {
          key: "image",
          label: "Collection",
          tdClass: "align-middle data-Table cursor-pointer",
          thClass: "bg-table-heading",
        },
       

      ],
      title:'',
      items: [],
      isLoadingFirstTime: true,
      isLoadingSelectedWixProducts: false,
      isLoadingDeleteProduct: false,
      isLoadingSync: false,
      isLoading: false,
      productProducts:[],
      queryObject: {
  "productsFirst": 50,
  "productsLast": null,
  "before": null,
  "after": null,
  "sortKey": "TITLE",
  "query": ""
}




    
    };
  },
  watch: {
  
 
  
    allCollection:{
      handler({selected,all}){
       this.shopifySelectAllCheck=all.every(collection=>selected.includes(collection.id))
      },
      deep:true
    },
   
  },
  computed: {
    ...mapGetters(["getNavbarData"]),
    allCollection(){
      return {selected:this.wixSelectedProducts,all:this.wixProducts}
    },
  
    wixAllProducts() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.wixProducts.map((collection) => collection.id);
    },
  },
  methods: {
   
 
  
   
   
    openWixProductModal() {
      
        this.isLoadingWixProductFirstTime = true;
        this.wixSelectedProducts=[];
      this.$refs.Modal.open();
      this.getProductsFromWix();
    },
    async syncSelected() {
      this.isLoadingSelectedWixProducts = true;
      await axios
        .post("/saveWixProducts", {
          selectedIds: this.wixSelectedProducts,
        })
        .then(() => {
          this.$refs.Modal.close();
          // this.getProducts("/collection/list");
          this.$emit('update')
          this.getProductsFromWix()
          this.$toasted.show("Collections sync successfully", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        })
        .catch((e) => {
          this.$toasted.show("Error occurred while syncing collections", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
          console.log(e);
        });
      this.isLoadingSelectedWixProducts = false;
    },
    searchProductQueryHandle(e) {
      this.title=e
      this.pageInfo.currentPage=1;
      this.handleSearchDebounced();
    },
    wixProductselectAllToggle() {
      this.wixSelectedProducts = [];
      if (this.shopifySelectAllCheck) {
        this.wixSelectedProducts = this.wixAllProducts;
      }
    },
    async syncAllCollections() {
      this.isLoadingSync = true;
      this.progress = 100;
      setTimeout(() => {
        this.$refs.progressbar.start();
      }, 1000);

      await axios
        .post("/collection/wix/sync")
        .then(() => {
          this.$refs.progressbar.stop();
          this.$refs.Modal.close();
           this.$emit('update');
          // this.getProducts("/collection/list");
          this.$toasted.show("Collections sync successfully", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        })
        .catch((e) => {
          this.$refs.progressbar.error();
          console.log(e);
          this.$toasted.show("Error occurred while syncing collections. ", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        });

      this.progress = 100;
      setTimeout(() => {
        this.isLoadingSync = false;
      }, 2000);
    },
    async getProductsFromWix() {
      this.isLoadingWixProducts = true;
     const data = {
     title:this.title,
      page:this.pageInfo.currentPage
     }
      await axios
        .post("/collection/wix/list",data)
        .then((response) => {
          if (response.status) {
            this.wixProducts=response.data.data.collections;
            this.pageInfo.currentPage=response.data.data.current_page;
             this.pageInfo.totalPages=response.data.data.total_pages;
          
          } else {
            this.$toasted.show("Error occurred getting collections. ", {
              theme: "toasted-primary",
              position: "bottom-center",
              duration: 2000,
            });
          }
          
        })
        .catch((e) => {
          console.log(e);
        });
      this.isLoadingWixProducts = false;
      this.isLoadingWixProductFirstTime = false;
    },
 

    handleSearchDebounced: debounce(function () {
      this.getProductsFromWix();
    }, 500), // 500ms debounce time, adjust as needed
   
  },
  mounted() {
   
  },
};
</script>
<style scoped>
.shopify-btn:active {
  background-color: #2e2f2f;
  color: #ffffff;
  border-color: #2e2f2f;
}
.shopify-btn {
  appearance: none;
  cursor: pointer;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #030303;
  color: #ffffff;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;

  font-size: 14px;

  transition: 150ms ease;
  transition-property: background-color, border-color;
  display: inline-block;

  border-radius: 6px;
}
.checkbox >>> .custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: var(--secondary-color);
  background-color: var(--secondary-color) !important;
}
.checkbox >>> .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem var(--secondary-color);
}
.leadTable >>> thead {
  border-radius: 6px;
  color: black;
  background: rgba(179, 175, 182, 0.07);
  border: none;
}
.leadTable >>> thead tr {
  border-radius: 10px;
}
.leadTable >>> thead th {
  border: none;
}
.loader {
  min-height: 40px;
}
.collection-image {
  height: 40px;
  width: 40px;
  /* border: 4px solid rgb(255, 255, 255); */
  border-radius: 50%;
  -o-object-fit: cover;
  object-fit: cover;
  background-size: cover;
  /* padding: 0.25rem; */
  /* background-color: #fff; */
}
.customPagination >>> li > button {
  color: #4d1b7e;
}

.customPagination >>> li.active > button {
  color: white;
  background-color: #4d1b7e !important;
  border-color: #4d1b7e;
}
.customPagination >>> li > button:hover {
  color: white;
  background-color: #6b4690 !important;
  border-color: #4d1b7e;
}
#collection-detail >>> button {
  background: transparent;
  border: none;
  margin: 0;
  padding: 0;
}
#collection-detail >>> button::after {
  display: none;
}
#collection-detail >>> ul {
  background: #ffffff;
  box-shadow: 0px 10px 20px -3px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
}
#collection-detail >>> li.topbarLink {
  color: #344054;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 5px;
}
#collection-detail >>> li a:active {
  color: #fff;
  text-decoration: none;
  background-color: #4d1b7e;
}
</style>
